import { render, staticRenderFns } from "./HostWS.vue?vue&type=template&id=683d179e&scoped=true&"
import script from "./HostWS.vue?vue&type=script&lang=js&"
export * from "./HostWS.vue?vue&type=script&lang=js&"
import style0 from "./HostWS.vue?vue&type=style&index=0&id=683d179e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "683d179e",
  null
  
)

export default component.exports