/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/

import i18n from "../i18n";

function errMessage(err) {
  if (!err.response) {
    return `${i18n.t("common.errorClient")} ${err.message}`;
  }

  let message = `${i18n.t("common.errorServer")} ${err.message}`;

  if (isObject(err)) {
    message = message + "\n" + `"${err.response.data.message}"`;
  }

  return message;
}

function isObject(err) {
  return (
    err.response.data &&
    typeof err.response.data === "object" &&
    err.response.data.message
  );
}

export default {
  errMessage,
};
