<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <span v-if="showIcon">
    <div @click="toTopic" :class="moveToTopic ? 'pointer' : ''">
      <fa-icon
        v-if="!initMessage"
        icon="clock"
        :size="size"
        style="color: orange"
      />
      <fa-icon
        v-if="initMessage && !successStatus"
        icon="exclamation-circle"
        :size="size"
        style="color: red"
      />
      <fa-icon
        v-if="initMessage && successStatus"
        icon="check-circle"
        :size="size"
        style="color: green"
      />
    </div>
  </span>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "HostWS",
  props: {
    topicId: {
      type: String,
      required: true,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    closeAfterGet: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "1x",
      validator: (value) =>
        [
          "md",
          "lg",
          "xs",
          "sm",
          "1x",
          "2x",
          "3x",
          "4x",
          "5x",
          "6x",
          "7x",
          "8x",
          "9x",
          "10x",
        ].indexOf(value) > -1,
    },
    moveToTopic: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      connection: null,
      successStatus: false,
      initMessage: null,
      subscription: null,
    };
  },
  async mounted() {
    this.subscription = await this.subscribe({
      key: this.topicId,
      handler: this.onMessage,
    });
  },
  computed: {},
  methods: {
    ...mapActions(["subscribe"]),
    onMessage(data) {
      if (!this.initMessage) {
        this.setInitMessage(data);
      }
      this.$emit("message", data);
    },
    setInitMessage(data) {
      this.initMessage = data;
      this.successStatus = data.success;
    },
    toTopic() {
      if (!this.moveToTopic) {
        return;
      }

      this.$router.push({ path: `/topics/${this.topicId}` });
    },
  },
  destroyed() {
    this.subscription.unsubscribe();
  },
};
</script>

<style scoped>
div {
  display: inline;
}
div.pointer {
  cursor: pointer;
}
</style>
