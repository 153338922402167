/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/

import i18n from '../../../i18n'

export const TopicStatus = {
  success: {
    code: 'success',
    text: i18n.t('topic.status[0]')
  },
  loading: {
    code: 'loading',
    text: i18n.t('topic.status[1]')
  },
  error: {
    code: 'error',
    text: i18n.t('topic.status[2]')
  }
}

