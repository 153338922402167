<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <div>
    <CRow>
      <CCol>
        <CButton class="back-button" @click="$router.go(-1)">{{
          $t("common.back")
        }}</CButton>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CForm>
          <CCard>
            <CCardHeader>
              <strong>
                {{ $t("topic.taskStatus") }} #{{ $route.params.id }}
              </strong>
            </CCardHeader>
            <CCardBody>
              <HostWS
                :topic-id="topicId"
                @message="getResult"
                :move-to-topic="false"
              ></HostWS>
              <strong> {{ status.text }} </strong>
              
              <div class="log-container">
                <CContainer>
                  <CRow v-for="(message, i) in log" :key="i">
                    <CCol md="2" class="">
                      {{ getDateFormatted(message.time) }}
                    </CCol>
                    <CCol md="2" class="">
                      {{ message.task }}
                    </CCol>
                    <CCol md="8" class="" v-html="message.msg"></CCol>
                  </CRow>
                </CContainer>
              </div>
            </CCardBody>
          </CCard>
        </CForm>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import moment from "moment";

import HostWS from "./../components/HostWS";

import { mapActions } from "vuex";
import utilMessages from "../../../utils/messages";
import { TopicStatus } from "./constants";

export default {
  name: "Topic",
  components: {
    HostWS,
  },
  data() {
    return {
      topicStatus: TopicStatus,
      topicId: this.$route.params.id,
      result: null,
      log: [],
      polling: null,
    };
  },
  mounted() {
    this.polling = setInterval(() => {
      this.getLog();
    }, 3000);
  },
  destroyed() {
    this.stopPooling();
  },
  computed: {
    status() {
      if (!this.result) {
        return this.topicStatus.loading;
      }

      return this.result.success
        ? this.topicStatus.success
        : this.topicStatus.error;
    },
  },
  methods: {
    ...mapActions(["fetchTopicLog"]),
    getResult(result) {
      this.result = result;
      clearInterval(this.polling);
      this.getLog();
    },
    async getLog() {
      try {
        const topicResult = await this.fetchTopicLog(this.topicId);
        this.log = topicResult.log;
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    getDateFormatted(dt) {
      return !!dt ? moment(dt).format("DD.MM.YYYY HH:mm:ss") : dt;
    },
    stopPooling() {
      if (!this.polling) {
        return;
      }

      clearInterval(this.polling);
    },
  },
};
</script>

<style scoped>
.back-button {
  padding-left: 0;
  padding-right: 0;
}

.back-button:focus {
  box-shadow: none;
}

.log-container {
  margin-top: 20px;
}
</style>
